import { useQueryClient } from '@tanstack/vue-query'
import dayjs from 'dayjs'
import { authQueries } from '..'
import type { AuthUser } from '../types'
import { useToken } from './useToken'
import { essayQueries } from '~/domains/essay'
import { contentQueries } from '~/domains/content'
import { getCheckoutConfigsQuery } from '~/domains/checkout/queries/getCheckoutConfigsQuery'
import { toast } from 'vue3-toastify'
import * as Sentry from "@sentry/nuxt";
import { useHandlePwaUpdate } from '~/domains/global/composables/useHandlePwaUpdate'

const NON_REDIRECT_ROUTE_NAMES = ['offline']

const AuthData = ref<AuthUser | null>(null)
const AuthLoaded = ref(false)

export const useAuth = () => {
  const router = useRouter()
  const route = useRoute()
  const config = useRuntimeConfig()
  const token = useToken()
  const queryClient = useQueryClient()
  const { checkVersionAndUpdate } = useHandlePwaUpdate()

  const redirectToLogin = () => {
    if (NON_REDIRECT_ROUTE_NAMES.includes(route.name as string)) {
      AuthLoaded.value = true
      return
    }
    router.push('/entrar')
  }

  // Re-authenticate user
  const reAuthenticate = async (replaceToken?: string, skipRedirect = false) => {
    console.log('re-authenticate', AuthLoaded.value, token.value, skipRedirect)
    AuthLoaded.value = false
    if (replaceToken) token.value = replaceToken

    if (!token.value) {
      if (!skipRedirect) return redirectToLogin()
      else {
        AuthLoaded.value = true
        return
      }
    }

    try {
      const data = await queryClient.fetchQuery({
        ...authQueries.reAuthQuery(token.value),
      })

      const updateStatus = await checkVersionAndUpdate()

      if (updateStatus) {
        return;
      }

      await Promise.all([
        // Fetch user data and user essays
        Promise.all([
          queryClient.prefetchQuery({
            ...essayQueries.getUserEssays(),
          }),
          queryClient.prefetchQuery({
            ...contentQueries.getThemesQuery(),
          }),
          queryClient.prefetchQuery({
            ...getCheckoutConfigsQuery(),
          }),
        ]),
        // Wait at least 400ms
        new Promise(resolve => setTimeout(resolve, 400)),
      ])

      AuthData.value = data
      AuthLoaded.value = true

      return true
    }
    catch (e: any) {
      console.log('error', e)
      if (e?.data === 'NOT_ALLOWED') {
        token.value = null
        return await Promise.all([
          redirectToLogin,
          queryClient.invalidateQueries({
            queryKey: ['reauth'],
          }),
        ])
      }

      if (e?.data === 'EXPIRED' || e?.statusCode === 401) {
        token.value = null

        toast.error('Sua sessão expirou, por favor, faça login novamente.')
        return await Promise.all([
          router.push('/entrar'),
          queryClient.invalidateQueries({
            queryKey: ['reauth'],
          }),
        ])
      }

      Sentry.captureException(e)

      await Promise.all([
        router.push('/offline'),
      ])
    }

    return false
  }

  const logOut = async () => {
    token.value = null
    AuthData.value = null
    AuthLoaded.value = false
    await queryClient.invalidateQueries({
      queryKey: ['reauth'],
    })
  }

  const startGoogleAuthentication = () => {
    // Merge URL with API URL
    const apiURL = config.public.apiURL
    const googleAuthPath = `/users/google`

    const googleAuthUrl = new URL(googleAuthPath, apiURL).toString()

    setTimeout(function () {
      document.location.href = googleAuthUrl
    }, 10)
  }

  const isLoggedIn = computed(() => AuthData.value !== null)
  const isPremium = computed(() => {
    return dayjs(AuthData.value?.subscriptionDate).isAfter(dayjs())
  })

  return {
    reAuthenticate,
    startGoogleAuthentication,
    logOut,
    data: AuthData,
    isLoading: computed(() => !AuthLoaded.value),
    isLoggedIn,
    isPremium,
  }
}
